import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/persekutuan',
    name: 'Fellowship',
    component: () => import(/* webpackChunkName: "fellowship" */ '../views/Fellowship.vue')
  },
  {
    path: '/jadwal',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue')
  },
  {
    path: '/persembahan',
    name: 'Offering',
    component: () => import(/* webpackChunkName: "offering" */ '../views/Offering.vue')
  },
  {
    path: '/persembahan/va',
    name: 'OfferingVa',
    component: () => import(/* webpackChunkName: "offering-va" */ '../views/offering/OfferingVa.vue')
  },
  {
    path: '/persembahan/qr',
    name: 'OfferingQr',
    component: () => import(/* webpackChunkName: "offering-qr" */ '../views/offering/OfferingQr.vue')
  },
  {
    path: '/player',
    name: 'Player',
    component: () => import(/* webpackChunkName: "player" */ '../views/Player.vue')
  },
  {
    path: '/detail/:id',
    name: 'DetailArticle',
    component: () => import(/* webpackChunkName: "detail-article" */ '../views/DetailArticle.vue')
  },
  {
    path: '/persekutuan/:id',
    name: 'DetailFellowship',
    component: () => import(/* webpackChunkName: "detail-fellowship" */ '../views/DetailFellowship.vue')
  },
  {
    path: '/ibadahumum/:sequence',
    name: 'Worship',
    component: () => import(/* webpackChunkName: "worship" */ '../views/Worship.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/term-of-use',
    name: 'TermOfUse',
    component: () => import(/* webpackChunkName: "term-of-use" */ '../views/TermOfUse.vue')
  },
  {
    path: '/document',
    name: 'Document',
    component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue')
  },
  {
    path: '/pdf',
    name: 'Pdf',
    component: () => import(/* webpackChunkName: "pdf" */ '../views/Pdf.vue')
  },
  {
    path: '/pendaftaran',
    redirect: '/detail/61aed56cf11ab6777b913841'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
